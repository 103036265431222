import styled from 'styled-components';
import config from '../config';

export default styled.div`
  display: flex;
  flex-wrap: wrap;

  h3 {
    width: 100%;
  }

  p {
    opacity: 0.6;
    margin-top: 3.2rem;
  }

  section {
    margin-right: 3.2rem;
  }

  @media (max-width: ${config.resolutions.mobileL}) {
    p {
      margin-top: 1.6rem;
    }
  }
`;
