import styled from 'styled-components';
import config from '../config';
import { hexStringToRGB } from '../../utils/css';

interface Line {
  totalItems: number;
}

export default styled('section')`
  margin-top: 4.8rem;
  overflow-x: scroll;
  position: relative;

  @media (max-width: ${config.resolutions.mobileL}) {
    margin-top: 3.3rem;
    overflow-y: scroll;
    height: 50rem;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-around;
  }
`;

export const Article = styled.article`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 5.5rem;

  & > p {
    position: relative;
    width: max-content;
  }

  &:first-child {
    margin-left: 6rem;
  }

  &::after {
    position: absolute;
    content: url('/assets/about-us/oval.svg');
    z-index: 1;
    height: 1.6rem;
    width: 1.6rem;
    top: 7.1rem;
  }
  & > p::before {
    content: ' ';
    background-color: #d8d8d8;
    position: absolute;
    height: 1px;
    width: 100%;
    top: 7.9rem;
  }

  &:nth-child(2n) > p::before {
    top: -4.4rem;
  }

  &:last-child > p::before {
    right: 50%;
    width: 50%;
  }

  &.bottom {
    margin-top: 10rem;
    flex-direction: column-reverse;
    margin-left: 0;
  }

  & > b {
    color: rgba(${hexStringToRGB(config.palette.darkBlue)}, 0.4);
  }

  @media (max-width: ${config.resolutions.mobileL}) {
    margin-top: 4rem;
    flex-direction: column-reverse;
    align-items: baseline;
    height: auto;
    position: relative;

    & > p {
      margin-top: 0.8rem;
      width: auto;
    }

    &:first-child {
      margin-left: 0;
      margin-top: 7rem;
    }

    &.bottom {
      margin-top: 4rem;
      margin-left: 0;
    }

    &::after {
      display: none;
    }

    &:nth-child(2n) > p::before {
      top: auto;
    }

    & > p::before {
      position: absolute;
      content: url('/assets/about-us/oval.svg');
      background-color: inherit;
      z-index: 1;
      height: 1.6rem;
      width: 1.6rem;
      left: -3.2rem;
      top: auto;
    }

    &::before {
      content: ' ';
      background-color: #d8d8d8;
      position: absolute;
      height: calc(100% + 4rem);
      width: 1px;
      left: -2.4rem;
    }

    &:first-child::before {
      height: calc(100% + 2rem);
    }

    &:nth-child(2n)::before {
      left: -2.4rem;
    }

    &:last-child::before {
      bottom: 50%;
      height: calc(50% + 4rem);
    }
  }
`;

export const DivLine = styled('div')<Line>`
  position: absolute;
  display: flex;
  top: 5.5rem;
  align-items: center;

  @media (max-width: ${config.resolutions.mobileL}) {
    position: static;
    flex-direction: column;
    width: auto;
  }
`;

export const DivTop = styled.div`
  display: flex;

  @media (max-width: ${config.resolutions.mobileL}) {
    display: flex;
    flex-direction: column;
  }
`;
