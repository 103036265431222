import React from 'react';
import { graphql } from 'gatsby';

import withI18next from '../../i18n/TemplateI18n';
import Header from '../../components/Header';
import PageTitle from '../../components/PageTitle';
import WhatIsIronhack from '../../components/WhatIsIronhack';
import Quickfacts from '../../components/QuickFacts';
import Carousel from '../../components/Carousel';
import Timeline from '../../components/Timeline';
import MeetOurTeam from '../../components/MeetOurTeam';
import Footer from '../../components/Footer';
import InternalLinks from '../../containers/InternalLinks';
import { LayoutPadding, LayoutOurValues } from './styles';
import AboutUsProps from '../../interface/template';
import useUserLocation from '../../hooks/useUserLocation';
import Banner from '../../components/Banner';

const AboutUs: React.SFC<any> = (props: AboutUsProps) => {
  const { t, pageContext } = props;
  const { pageName } = pageContext;
  const { userLocation } = useUserLocation();

  return (
    <>
      {userLocation?.country === 'NL' && <Banner type="stap" />}
      <Header pageName={pageName} showBackground>
        <PageTitle
          subtitle={t('about-us:aboutUs.mainHeadline.header_description')}
          title={t('about-us:aboutUs.mainHeadline.title')}
        />
      </Header>
      <WhatIsIronhack />
      <Quickfacts />
      <Carousel
        backgroundColor="rgb(45,197,250,0.1)"
        cards={[
          {
            titleCard: t('about-us:aboutUs.ourValues.values.01.title'),
            text: [
              t('about-us:aboutUs.ourValues.values.01.description.01'),
              t('about-us:aboutUs.ourValues.values.01.description.02'),
            ],
            url: '/assets/iron-values/can-do-attitude.png',
          },
          {
            titleCard: t('about-us:aboutUs.ourValues.values.02.title'),
            text: [
              t('about-us:aboutUs.ourValues.values.02.description.01'),
              t('about-us:aboutUs.ourValues.values.02.description.02'),
            ],
            url: '/assets/iron-values/love-people-and-their-stories.png',
          },
          {
            titleCard: t('about-us:aboutUs.ourValues.values.03.title'),
            text: [
              t('about-us:aboutUs.ourValues.values.03.description.01'),
              t('about-us:aboutUs.ourValues.values.03.description.02'),
            ],
            url: '/assets/iron-values/passion-above-anything-else.png',
          },
          {
            titleCard: t('about-us:aboutUs.ourValues.values.04.title'),
            text: [t('about-us:aboutUs.ourValues.values.04.description')],
            url: '/assets/iron-values/collaborate-to-grow.png',
          },
        ]}
        extend={LayoutOurValues}
        title={t('about-us:aboutUs.ourValues.title')}
      />
      <Timeline extend={LayoutPadding} />
      <MeetOurTeam extend={LayoutPadding} locale={props.pageContext.locale} />
      <InternalLinks />
      <Footer page={pageName} />
    </>
  );
};

export const query = graphql`
  query ($locale: String!) {
    locales: allLocale(
      filter: {
        lng: { eq: $locale }
        ns: {
          regex: "/(menu)|(about-us)|(staff)|(breadcrumbs)|(seo)|(breadcrumbs)|(banner)|(navigation)/"
        }
      }
    ) {
      ...TranslationFragment
    }
  }
`;

export default withI18next()(AboutUs);
