import React from 'react';
import classNames from 'classnames';
import uniqid from 'uniqid';
import Layout from '../atoms/layout';
import { Title2 } from '../atoms/headers';
import Section, { Article, DivLine, DivTop } from './Timeline';
import { Text2, Text5 } from '../atoms/body';
import { withTranslation } from 'react-i18next';

function Timeline(props: any) {
  const { t, ...other } = props;

  const dataline = [
    {
      date: t('about-us:aboutUs.timeline.events.01.year'),
      title: t('about-us:aboutUs.timeline.events.01.description')
    },
    {
      date: t('about-us:aboutUs.timeline.events.02.year'),
      title: t('about-us:aboutUs.timeline.events.02.description')
    },
    {
      date: t('about-us:aboutUs.timeline.events.03.year'),
      title: t('about-us:aboutUs.timeline.events.03.description')
    },
    {
      date: t('about-us:aboutUs.timeline.events.04.year'),
      title: t('about-us:aboutUs.timeline.events.04.description')
    },
    {
      date: t('about-us:aboutUs.timeline.events.05.year'),
      title: t('about-us:aboutUs.timeline.events.05.description')
    },
    {
      date: t('about-us:aboutUs.timeline.events.06.year'),
      title: t('about-us:aboutUs.timeline.events.06.description')
    },
    {
      date: t('about-us:aboutUs.timeline.events.07.year'),
      title: t('about-us:aboutUs.timeline.events.07.description')
    },
    {
      date: t('about-us:aboutUs.timeline.events.08.year'),
      title: t('about-us:aboutUs.timeline.events.08.description')
    },
    {
      date: t('about-us:aboutUs.timeline.events.09.year'),
      title: t('about-us:aboutUs.timeline.events.09.description')
    },
    {
      date: t('about-us:aboutUs.timeline.events.10.year'),
      title: t('about-us:aboutUs.timeline.events.10.description')
    },
    {
      date: t('about-us:aboutUs.timeline.events.11.year'),
      title: t('about-us:aboutUs.timeline.events.11.description')
    },
    {
      date: t('about-us:aboutUs.timeline.events.12.year'),
      title: t('about-us:aboutUs.timeline.events.12.description')
    }
  ];

  return (
    <Layout {...other}>
      <Title2 as="h2">{t('about-us:aboutUs.timeline.title')}</Title2>
      <Section>
        <DivTop>
          {dataline.map((data, index) => (
            <Article
              key={uniqid()}
              className={classNames({
                bottom: index % 2
              })}
            >
              <Text2 as="p">{data.title}</Text2>
              <Text5>{data.date}</Text5>
            </Article>
          ))}
        </DivTop>
        <DivLine totalItems={dataline.length}>
          <img
            src="/assets/icons/ironhack_logos/logo.svg"
            alt="Ironhack Logo"
          />
        </DivLine>
      </Section>
    </Layout>
  );
}

export default withTranslation()(Timeline);
