import { css } from 'styled-components';
import config from '../../components/config';

const LayoutPadding = css`
  padding-top: 6.4rem;

  @media (max-width: ${config.resolutions.mobileL}) {
    padding-bottom: 2.4rem;
  }
`;

const LayoutOurValues = css`
  @media (max-width: ${config.resolutions.mobileL}) {
    padding-top: 2.4rem;
    padding-bottom: 2.4rem;
    background-color: ${config.palette.lightBlueTransparent('0.1')};
  }
`;

export { LayoutOurValues, LayoutPadding };
