import styled from 'styled-components';
import config from '../config';

export default styled.section`
  display: flex;
  flex-wrap: wrap;
  margin-top: 3.2rem;
  margin-bottom: 4.8rem;

  article {
    margin-bottom: 3.2rem;
  }

  @media (max-width: ${config.resolutions.mobileL}) {
    flex-direction: column;
  }
`;
