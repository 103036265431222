import React from 'react';
import Layout from '../atoms/layout';
import { Title2 } from '../atoms/headers';
import { Text3, Text4 } from '../atoms/body';
import Section, { Article, LayoutQuick } from './QuickFacts';
import { withTranslation } from 'react-i18next';

const Quickfacts: React.SFC<any> = (props: any) => {
  const { t } = props;
  return (
    <Layout extend={LayoutQuick}>
      <Title2 as="h2">{t('about-us:aboutUs.quickFacts.title')}</Title2>
      <Text3 as="p" className="subtitle-quickfact">
        {t('about-us:aboutUs.quickFacts.description.01')}
      </Text3>
      <Section>
        <Article>
          <div>
            <img src="/assets/icons/checkbox/check-box.svg" alt="Check box" />
            <Text4 as="p">
              {t('about-us:aboutUs.quickFacts.description.02')}
            </Text4>
          </div>
          <div>
            <img src="/assets/icons/checkbox/check-box.svg" alt="Check box" />
            <Text4 as="p">
              {t('about-us:aboutUs.quickFacts.description.03')}
            </Text4>
          </div>
        </Article>
        <Article>
          <div>
            <img src="/assets/icons/checkbox/check-box.svg" alt="Check box" />
            <Text4 as="p">
              {t('about-us:aboutUs.quickFacts.description.04')}
            </Text4>
          </div>
          <div>
            <img src="/assets/icons/checkbox/check-box.svg" alt="Check box" />
            <Text4 as="p">
              {t('about-us:aboutUs.quickFacts.description.05')}
            </Text4>
          </div>
        </Article>
      </Section>
    </Layout>
  );
};

export default withTranslation()(Quickfacts);
