import styled, { css } from 'styled-components';
import config from '../config';

export default styled.section`
  display: flex;
  justify-content: space-between;
  padding: 0 1.6rem;

  &:firts-child {
    margin-right: 4.8rem;
  }

  @media (max-width: ${config.resolutions.mobileL}) {
    flex-direction: column;
  }
`;

export const Article = styled.article`
  display: flex;
  flex-direction: column;

  & > div {
    display: flex;
    align-items: center;
    margin-top: 3.2rem;

    img {
      margin-right: 1.6rem;
    }
  }

  @media (max-width: ${config.resolutions.mobileL}) {
    & > div {
      margin-right: 0;
    }
  }
`;

const LayoutQuick = css`
  padding-top: 6.4rem;
  padding-bottom: 6.4rem;

  h2 {
    font-size: 3.2rem;
    line-height: 150%;

    @media (max-width: ${config.resolutions.mobileL}) {
      font-size: 2.4rem;
      line-height: 130%;
    }
  }

  & > p {
    margin-top: 1.6rem;
    color: ${config.palette.darkBlueTransparent('0.6')};
  }

  & > section > article:first-child {
    margin-right: 4.8rem;
  }

  &.subtitle-quickfact {
    opacity: 0.6;
    margin-top: 1.6rem;
  }
`;

export { LayoutQuick };
