import React from 'react';
import classNames from 'classnames';
import Layout from '../atoms/layout';
import { Title1 } from '../atoms/headers';
import { Text3 } from '../atoms/body';
import Props from '../interfaces/WhatIsIronhack';
import Div from './WhatIsIronhack';
import { withTranslation } from 'react-i18next';

import './style.scss';
const WhatIsIronhack: React.SFC<any> = (props: Props) => {
  const { className = '', t } = props;
  return (
    <Layout
      className={classNames({
        whatIronhack: true,
        [className]: true
      })}
    >
      <Div>
        <Title1 as="h2">{t('about-us:aboutUs.whatIsIronhack.title')}</Title1>
        <section>
          <Text3 as="p">
            {t('about-us:aboutUs.whatIsIronhack.description.01')}
          </Text3>
          <Text3 as="p">
            {t('about-us:aboutUs.whatIsIronhack.description.02')}
          </Text3>
        </section>
      </Div>
      <img
        src="/assets/staff/gonzalo-ariel.png"
        alt={t('about-us:aboutUs.whatIsIronhack.imageAlt')}
      />
    </Layout>
  );
};

export default withTranslation()(WhatIsIronhack);
