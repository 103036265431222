import React from 'react';
import Layout from '../atoms/layout';
import ListComponent from '../ListComponent';
import TeamMember from '../TeamMember';
import Section from './MeetOurTeam';
import { Title1 } from '../atoms/headers';
import { withTranslation } from 'react-i18next';
import {
  useStaff,
  staffByPosition,
  sortStaffByCampus
} from '../../services/datoStaff';

function MeetOurTeam(props: any): JSX.Element {
  const { t, locale, ...other } = props;
  const staff = useStaff(locale);

  const leaders = staffByPosition(staff, ['leader']);
  const gMs = staffByPosition(staff, ['generalManager']);
  const sortedGMs = sortStaffByCampus(gMs);

  return (
    <Layout {...other}>
      <Title1 as="h2">{t('about-us:aboutUs.meetTeam.title')}</Title1>
      <Section>
        <ListComponent
          listItems={leaders.concat(sortedGMs)}
          ChildrenComponent={TeamMember}
        />
      </Section>
    </Layout>
  );
}

export default withTranslation()(MeetOurTeam);
